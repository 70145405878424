<template>
  <div class="basePeopleContainer">
    <div style="overflow-y: scroll;height:100%;width:100%" ref="GoTopContent">
      <Title></Title>
      <div class="banner">
          <h5 style="font-size:46px;font-weight:600;padding-bottom:40px">师资培养与发展系统</h5>
          <button class="buttonShow" @click="$router.push('/freeTrial')">立即预约演示</button>
      </div>

      <div class="mainFunction">
        <IntroduceCare
          :fontDesc="mainFunction"
          class="mainFunctionCard"
        ></IntroduceCare>
      </div>

      <div class="systemArchitecture">
          <h4 class="descH4">系统功能图</h4>
          <span class="line"></span>
          <img src="@/assets/product/ProductSzpyyfzxt/gnt.png" alt="" style="width:1000px;margin-top:50px">
      </div>

      <div class="systemValue">
        <h4 class="descH4">系统价值优势</h4>
        <span class="line"></span>
        <div class="valueContent">
          <div class="valueItem" v-for="(item,index) in valueList" :key="index">
            <div class="valueItemMask"></div>
            <img :src="item.src" alt="" class="valueLeft">
            <img :src="item.hoverScr" alt="" class="valueLeftHover">
            <div class="valueLine"></div>
            <div class="valueRight">
              {{item.content}}
            </div>
          </div>
        </div>
      </div>

      <Foot></Foot>
    </div>

    <BackTop class="backTop"></BackTop>
  </div>
</template>

<script>
export default {
  name: 'ProductSzpyyfzxt',
  data () {
    return {
      mainFunction: [
        {
          title: '师资培养与发展系统',
          fontLeft: false,
          p: [
            {
              desc: '师资培养系统是对员工培训、国内进修、国外进修、挂职锻炼、外聘专家、资格考试、学术兼职、荣誉称号等业务的综合管理，助力员工队伍建设，促进员工职业发展。'
            }
          ],
          src: require('@/assets/product/ProductSzpyyfzxt/gaint.png')
        }
      ],
      valueList: [
        { content: '全方位整合员工发展信息，形成员工行为数据分析的基础', src: require('@/assets/solute/SmartCampus/smartOne.png'), hoverScr: require('@/assets/oneHover.png') },
        { content: '业务全流程线上化，沉淀师资培养数据。', src: require('@/assets/solute/SmartCampus/smartTwo.png'), hoverScr: require('@/assets/towHover.png') },
        { content: '勾勒员工成长年轮，促进员工职业发展', src: require('@/assets/solute/SmartCampus/smartThree.png'), hoverScr: require('@/assets/threeHover.png') }
      ]
    }
  },
  methods: {
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/moProduct/moProductSzpyyfzxt')
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  },
  created () {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  }
}
</script>

<style scoped lang='scss'>
*{
    margin: 0;
    padding: 0;
}
img {
    object-fit: cover;
}
button:hover{
    cursor: pointer;
}
.line{
    display: inline-block;
    width: 40px;
height: 6px;
background: #D70C0C;
border-radius: 3px;
}
.buttonShow:hover{
box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
}
.descH4{
font-size: 36px;
font-weight: 500;
color: #222222;
padding: 60px 0 20px;
}
.basePeopleContainer{
  position: relative;
  top: 0;
  left: 0;
  min-width: 1200px;
  height: 100vh;
  overflow: hidden;
}
.banner{
    width: 100%;
    height: 460px;
    background: url('~@/assets/product/ProductSzpyyfzxt/banner.png') center no-repeat;
    background-size: cover;
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.banner button{
width: 200px;
height: 60px;
background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
border-radius: 34px;
border: 0;
color: #fff;
font-size: 16px;
}

.systemArchitecture{
    width: 100%;
height: 900px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
display: flex;
flex-direction: column;
align-items: center;
}
.mainFunction{
   width: 100%;
height: 450px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .mainFunctionCard .CareContent{
    padding: 45px 0 40px;
}
::v-deep .mainFunctionCard .CareContent .right img{
width: 560px;
}

.systemValue{
     width: 100%;
height: 490px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
display: flex;
flex-direction: column;
align-items: center;
}

.valueContent{
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
}
.valueItem{
  position: relative;
  top: 0;
  left: 0;
  width: 386px;
  height: 250px;
  margin-right: 21px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 0 40px;
  cursor: pointer;
}
.valueItemMask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.valueItem:last-child{
  margin-right: 0px;
}
.valueLeft{
  width: 68px;
}
.valueLeftHover{
  width: 68px;
  display: none;
}
.valueLine{
  width: 30px;
  height: 4px;
  background: #D70C0C;
  margin: 14px 0 20px;
}
.valueRight{
  font-size: 18px;
  font-weight: 400;
  color: #444444;
  line-height: 28px;
  text-align: center;
}
.valueItem:hover{
  box-shadow: 0px 8px 20px 0px rgba(173,0,0,0.3);
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  .valueItemMask{
    display: block;
    background: url('~@/assets/hoverBg.png') center no-repeat;
    background-position: top left;
  }
  .valueRight{
    color: #fff;
  }
  .valueLine{
    background-color: #fff;
  }
  .valueLeftHover{
    display: block;
  }
  .valueLeft{
    display: none;
  }
}
</style>
